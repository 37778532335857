import Navbar from './components/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact'
import Team from './components/Team';
import Service from './components/Service';



function App() {
  return (
<>
<div className=' text-center text-capitalize top'>welcome to KMD Middle East</div>
<BrowserRouter>
<Navbar/>

<Routes>
<Route path='' element={<Home/>}/>
<Route path='/about' element={<About/>}/>
<Route path='/contact' element={<Contact/>}/>
<Route path='/team' element={<Team/>}/>
<Route path='/service' element={<Service/>}/>

</Routes>
</BrowserRouter>



</>
  );
}

export default App;
