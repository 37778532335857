import React from 'react'
import '../App.css'
import { Link } from 'react-router-dom'

const Services = (props) =>{
    return(
        <>
        <Link to="/service" className='linkforservice'>
           <div className='serviceborder'>
           <div className='imagemain'>

           <img className='img-fluid serviceimg mt-4' src={props.image}/>
           </div>
            
            <h4 className='serviceheading '>{props.name}</h4>
            {/* <p className='servicepara mt-4'>{props.paragraph}</p> */}
           </div>
           </Link>
        </>
    )
}


export default Services;