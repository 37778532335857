import React from 'react'
import { Link } from 'react-router-dom';
import '../App.css'
import Services from '../components/Services';
import sdata from '../components/Array'
import Service from '../components/Service';
const Firstcard = () => {






return(
<>
<div className="container-fluid cardone">
            <div className="container-fluid mt-5">
            <div className="row">
             <div className="col-md-6 ">
                    <img className="img-fluid strategy" src="/images/visionag.png" alt="img"/>
                
                    
             </div>
            <div className="col-md-6 ">
            {/* <hr className='vertical-hr'></hr> */}
            <p className="statusabout"><span className="lines"></span>About KMD Middle East:</p>
            {/* <h2 className=" text-capitalize">Smart Solution For Your Digital Business</h2> */}
            <p className="  helocss rapidly">KMD Middle East is one of the UAE leading professional management and consultancy Firm with specialties in accounting & bookkeeping, audit & assurance, financial & business advisory, taxation, ERP implementation and support, company incorporation, and other professional services in the field of business and finance.<br/>
We specialize in providing a comprehensive suite of financial solutions designed to unlock the full potential of businesses. With our diverse expertise and tailored strategies, we work closely with clients to address their specific needs and challenges, ensuring effective financial management and sustainable growth.<br/>
Whether it’s optimizing accounting processes, navigating complex tax regulations, or enhancing operational efficiency through ERP systems, our team is dedicated to delivering results that elevate
businesses to new heights.<br/>
By partnering with us, clients benefit from personalized guidance, proactive insights, and hands-on support that empowers them to make informed decisions and achieve their business objectives.<br/>
With KMD Middle East as your trusted financial partner, you can confidently navigate the complexities of the business landscape and drive success.
<h4 className='statusabout mt-3'>Our Vision:</h4>
Inspired by the founding Fathers of the UAE and His Highness Sheikh Mohammad Bin Rashid Al Maktoum, We strive for excellence in everything we undertake and the upliftment of our community.

<h1 className='statusabout mt-3'>Our Mission:</h1>
Our mission is to serve the clients to their utmost satisfaction that helps them to make the best business decisions which add value to their businesses.</p>
               {/* <ul>
                <li className="data">Strategy and Innovation</li>
                <li className="data">Data Analytics and Solutions</li>
                <li className="data">Sales and Market Research</li>
               </ul>
           <Link to="/team"> <button className="quote px-4 py-2 mt-4">Meet Our Team</button> </Link> */}
            </div>
            </div>


            </div>
           
            </div>


            <div className="container-fluid mainstatus mt-5 ">
       {/* <div className="container-fluid mainstatus">
       <div className="row  ">
       <div className="col-md-6 mt-5 visioncontainer">
 <img className="img-fluid mt-3 px-4 py-4" src="/images/visionag.png"/>
       </div>
       <div className="col-md-6 mission mt-5 visioncontainer">
         <div className="mt-3">
            <h1>OUR VISION</h1>
            <p className='offering' >Our Mission is “Offering manag-ement and financial services to corporations, guiding policy<br/>
            formulation to attain strategic financial and sustainable goals, all within the constraints of<br/>
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic<br/>
             development that brings those numbers to fruition.”</p>
         </div>
         <div className="mt-3">
            <h1>Our Mission</h1>
            <p className='offering'>Our Mission is “Offering manag-ement and financial services to corporations, guiding policy<br/>
            formulation to attain strategic financial and sustainable goals, all within the constraints of<br/>
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic<br/>
             development that brings those numbers to fruition.”</p>
         </div>
         <div className="mt-3">
            <h1>OUR STRATEGY</h1>
            <p className='offering' >Our Mission is “Offering manag-ement and financial services to corporations, guiding policy<br/>
            formulation to attain strategic financial and sustainable goals, all within the constraints of<br/>
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic<br/>
             development that brings those numbers to fruition.”</p>
         </div>
       </div>

       </div>

       </div> */}

       <div className='container-fluid'>
<div className='row'>
<div className='col'>
<p className="statusaboutcompany text-center mt-5"><span className="lines"></span>Our Services</p>
{/* <p className='consultants mt-5'>KMD Middle East specializes in providing a comprehensive suite of financial solutions designed to unlock the full potential of businesses. Our offerings span various critical areas<br/>   
including bookkeeping, accounting, tax consultancy, CFO services, auditing, financial analysis, ERP implementation, corporate training, business setup, logistics, and recruitment.</p>
<p className='consultants mt-3'>With our diverse expertise and tailored strategies, we work closely with clients to address their specific needs and challenges, ensuring effective financial management and sustainable growth.<br/>
 Whether it’s optimizing accounting processes, navigating complex tax regulations, or enhancing operational efficiency through ERP systems, our team is dedicated to delivering results that elevate<br/> businesses to new heights.</p>
<p className='consultants mt-3'>By partnering with us, clients benefit from personalized guidance, proactive insights, and hands-on support that empowers them to make informed decisions and achieve their business objectives.<br/>
 With KMD Middle East as your trusted financial partner, you can confidently navigate the complexities of the business landscape and drive success.</p> */}

</div>

</div>

</div>


<div className='container-fluid mt-5 '>
<div className='row'>
    <div className='col-md-4'><Services name={sdata[0].name}
        image={sdata[0].image}
        paragraph={sdata[0].paragraph}

    /></div>
     <div className='col-md-4'><Services name={sdata[1].name}
        image={sdata[1].image}
        paragraph={sdata[1].paragraph}

    /></div>
     <div className='col-md-4'><Services name={sdata[2].name}
        image={sdata[2].image}
        paragraph={sdata[2].paragraph}

    /></div>


    <div className='container-fluid mt-5'>
        <div className='row'>
        <div className='col-md-4'><Services name={sdata[3].name}
        image={sdata[3].image}
        paragraph={sdata[3].paragraph}

    /></div>
    <div className='col-md-4'><Services name={sdata[4].name}
        image={sdata[4].image}
        paragraph={sdata[4].paragraph}

    /></div>
    <div className='col-md-4'><Services name={sdata[5].name}
        image={sdata[5].image}
        paragraph={sdata[5].paragraph}

    /></div>
        </div>
    </div>



    <div className='container-fluid mt-5'>
        <div className='row'>
        <div className='col-md-4'><Services name={sdata[6].name}
        image={sdata[6].image}
        paragraph={sdata[6].paragraph}

    /></div>
    <div className='col-md-4'><Services name={sdata[7].name}
        image={sdata[7].image}
        paragraph={sdata[7].paragraph}

    /></div>
    <div className='col-md-4'><Services name={sdata[8].name}
        image={sdata[8].image}
        paragraph={sdata[8].paragraph}

    /></div>
        </div>
    </div>
    <div className='container-fluid blackdiv mt-5'>
    <div className='row'>
    <div className='col-md-4'><Services name={sdata[9].name}
        image={sdata[9].image}
        paragraph={sdata[9].paragraph}

    /></div>
    {/* <div className='col-md-4'><Services name={sdata[10].name}
        image={sdata[10].image}
        paragraph={sdata[10].paragraph}

    /></div> */}
    <div className='col-md-4 viewall'>
    <Link to="/service"><button className="quote px-4 py-2 mt-4">View All Services</button></Link>

    </div>
    </div>

    </div>
    
   

   
    
</div>
 
</div>



       </div>

<div className="container-fluid cardonesecond">
   <div className="row">
      <div className="col-md-6 px-5 ">
        <img className="img-fluid strategylike" src="/images/strategy.png" alt="img"/>
      </div>
        <div className="col-md-6 px-5 businessgoals ">
        {/* <p className="statusaboutagain"><span className="lines responsive-heading"></span>P R O F E S S I O N A L   <span className="skills">S K I L L S</span>  </p> */}
        <h2 className="mt-5 text-capitalize">We Make Your Business Goals Our Priority</h2>

<div className="skill">
    <p className="skill-name mt-4">VAT & Corporate Tax Planning</p>
    <div className="progress-bar">
      <div className="progressone" >85%</div>
    </div>
</div>
  <div className="skill">
    <p className="skill-name mt-4">Accounting & Bookkeeping</p>
    <div className="progress-bar">
      <div className="progresstwo" >95%</div>
    </div>
  </div>
  <div className="skill">
    <p className="skill-name mt-4">Audit & Assurance</p>
    <div className="progress-bar">
      <div className="progressthree" >80%</div>
    </div>
  </div>
  <div className="skill">
    <p className="skill-name mt-4">ERP Implementation & Support</p>
    <div className="progress-bar">
      <div className="progressfour" >90%</div>
    </div>
  </div>
 </div>
     </div>
  </div>


<div className='container-fluid consultation mt-5'>
    <div className='row'>
        <div className='col-md-8'>
            <h1 className='ready'>Get Free Consultation Now! We Are Ready To Help Your Business</h1>
            <p className='steady'>Embark on a transformative journey. Unlock a world of possibilities with a free consultation, where your business dreams meet our expertise, ready to redefine success together.

GET FREE CONSULTING
</p>
        </div>
        <div className='col-md-4 gone'> <Link to="/service"><button className="quotett px-4 py-2 mt-4 ">View All Services</button></Link></div>
    </div>
</div>


{/* <div className='container-fluid latestproject'>
<div className='row'>
<div className='col'>
<p className=" text-center mt-5 team team-header"><span className="linesagain"></span>MEET OUR TEAM</p>
<h1 className='text-center mt-3'>ALL THE PEOPLE BEHIND THE LATEST PROJECT</h1>

<div class="team-section">
 
  <p class="team-description">
    Our dedicated professional team leverages extensive business acumen to serve all our clients. Upholding values of integrity, objectivity, professionalism, honesty, confidentiality, and unwavering commitment to excellence, we strive to alleviate the burdens of financial management and compliance...
  </p>
  <div class="team-container container">
  <div className='row team_subdiv'>

  
    <div className="team-member col-md-3">
      <img className='ceo_image' src="/images/ceo.png" alt=""/>
      <div className="member-info">
        <p className="member-name teamcolor">Mr. Muhammad Ikram</p>
        <p className="member-title">CEO & Founder</p>
      </div>
    </div>
    <div className="team-member col-md-3">
        <img src="path-to-image-of-member.jpg" alt="Mr. Muhammad Ikram"/>
        <div className="member-info">
          <p className="member-name teamcolor">Mr. Muhammad Ikram</p>
          <p className="member-title teamcolor">CEO & Founder</p>
        </div>
      </div>
      <div className="team-member col-md-3">
        <img src="path-to-image-of-member.jpg" alt="Mr. Muhammad Ikram"/>
        <div className="member-info">
          <p className="member-name teamcolor">Mr. Muhammad Ikram</p>
          <p className="member-title teamcolor">CEO & Founder</p>
        </div>
      </div>
    
      </div>
  </div>
</div>

</div>

</div>

</div> */}


</>
)

}


export default Firstcard;  