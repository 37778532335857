import React from 'react'
import '../App.css'

const Landingpage = () =>{

    return(
        <>
   <div className='containermain'>
        <img className='woman img-fluid' src='/images/woman.png'/>
        <div className='text-overlay'>
        {/* <p className="statusaboutt"><span className="linest"></span>About Company</p> */}
        <h1 className='trust '>BUILDING TRUST THROUGH QUALITY SERVICES</h1>
        {/* <button className="quote px-4 py-2 mt-4">Explorer Now</button> */}
        </div>
    </div>
        </> 
    )
}
export default Landingpage;