import React from 'react';
import '../App.css'
import { Link } from 'react-router-dom';
const Footer = ( ) => {
    return(

        <>
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h5 className='lastfooter'>Company</h5>
        <Link to="/about">About Us</Link>
        <Link to="/service">Services</Link>
        <Link to="/contact">Contact</Link>
        <Link to="#">FAQ's</Link>
      </div>
      <div class="col-md-4">
        <h5 className='lastfooterslide'>Solutions</h5>
        <Link to="/service">Accounting & Bookkeeping</Link>
        <Link to="/service">Audit and Assurance</Link>
        <Link to="/service">Corporate Tax (CT)</Link>
        <Link to="/service">Excise Tax</Link>
        <Link to="/service">Management Consultancy</Link>
      </div>
      <div class="col-md-4">
        <h5 className='lastfooter '>Get In Touch</h5>
        <ul class="contact-info">
          <li><span class="glyphicon glyphicon-earphone"></span>+971 52 474 4983</li>
          <li><span class="glyphicon glyphicon-earphone"></span>+971 52 752 1155</li>
        
          <li><span class="glyphicon glyphicon-envelope"></span>info@kmd-me.com</li>
          <li><span class="glyphicon glyphicon-map-marker"></span>www.kmd-me.com</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <p>© 2024 KMD Middle East All Rights Reserved</p>
      </div>
      <div class="col-md-4 text-right d-flex">
        <Link to="https://www.facebook.com/profile.php?id=61559479317899" class="social-icon"><img class="fab fa-facebook-f lasticons"  src='/images/facebook.png' alt='Facebook'/></Link>
        <Link to="https://www.linkedin.com/in/muhammad-kamran-b0b61b307/" class="social-icon"><img class="fab fa-linkedin-in lasticons" src='/images/linkedin.png ' alt='LinkedIN'/></Link>
        <Link to="https://www.youtube.com/@KMDMiddleEast" class="social-icon"><img class="fab fa-linkedin-in lasticons" src='/images/Youtube.png ' alt='youtube'/></Link>
        <Link to="https://www.instagram.com/kamran.kmd" class="social-icon"><img class="fab fa-instagram lasticons" src='/images/instagram.png' alt='instagram'/></Link>
        <Link to="https://wa.me/+971524744983"><img class="fab fa-instagram lasticons " src="/images/whatsapp.png" alt='whatsapp'/></Link>

 </div>
    </div>
  </div>
</footer>

        </>
    )
}
export default Footer;