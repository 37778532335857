import React from 'react'
import Afterteam from '../components/Afterteam';
import Landingpage from '../components/Landingpage';
import Firstcard from '../components/Firstcard';
import '../App.css';
import Footer from './Footer';

const Home = () =>{

    return(
        <>
<Landingpage/> 
 <Firstcard/> 
 <Afterteam/> 
 <Footer/>
        </>
    )
}
export default Home;