import React from 'react'
import '../App.css'
// import About from './components/About'
import { Link } from 'react-router-dom'
  
const Navbar = ()=>{
return(
    <>
  <nav className="navbar navbar-expand-lg navbar-light navback ">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img className="logoadjust" src='/images/Picture1.png'/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
   
<ul className="navbar-nav me-auto mb-2 mb-lg-0 centerednavbar-list">
        <li className="nav-item">
          <Link className="nav-link  act  " aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item ">
        <Link className="nav-link navbarhome" to="/service">Services</Link>
        </li>
        <li className="nav-item ">
        <Link className="nav-link navbarhome" to="/team">Team</Link>
        </li>
        <li className="nav-item ">
        <Link className="nav-link navbarhome" to="/about">About</Link>
        </li>
        <li className="nav-item ">
        <Link className="nav-link navbarhome" to="/contact">Contact Us</Link>
        </li>
        
       
       </ul>
       <Link to="/contact"> <button className="text-capitalize quote px-5 py-2" href="/contact">get a quote</button> </Link>
    
    </div>
  
  </div>
</nav>
    </>
)


}
export default Navbar;