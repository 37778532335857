const sdata=[
    {name:"Accounting & Bookkeeping",
    image:"./images/eight.png",
    },
{name:"Audit and Assurance",
image:"./images/nine.png",
}
,
{name:"Value Added TAX (VAT)",
image:"./images/ten.png",
paragraph:"Transform your brand narrative with our expertise craf-ting compelling stories and building connections for a lasting impact"},
{name:"Corporate Tax (CT)",
image:"./images/eleven.png",
paragraph:"Unleash the power of strategic marketing with us where creativity meets results, and your brand stands out in every advertisement."},
{name:"Excise Tax",
image:"./images/twelve.png",
paragraph:"Chart your course to success with our Business Planning expertise turning ideas into actionable strategies for sustainable growth."},
{name:"Economic Substance Regulation (ESR)",
image:"./images/eight.png",
paragraph:"Experience innovation and precision with our Tax Accounting Service, streamlining your business's tax journey for success and growth."},
{name:"Management Consultancy",
image:"./images/nine.png",
paragraph:"Comprehensive bookkeeping and accounting solutions tailored to your business needs, ensuring financial accuracy and compliance"},
{name:"Odoo ERP",
image:"./images/ten.png",
paragraph:"Experience innovation and precision with our Tax Accounting Service, streamlining your business's tax journey for success and growth."},
{name:"Oracle Application",
image:"./images/eleven.png",
paragraph:"Transform your brand narrative with our expertise craf-ting compelling stories and building connections for a lasting impact"},
{name:"Hyperion Financial Management",
image:"./images/twelve.png",
paragraph:"Experience innovation and precision with our Tax Accounting Service, streamlining your business's tax journey for success and growth."},
{name:"",
image:""},
]

export default sdata;