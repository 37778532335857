import React from 'react';
import '../App.css'
import { Link } from 'react-router-dom';

const Afterteam = () =>{
return(
    <>
<div className="container-fluid cardonesecond cardsstatus mt-5">
  <div className="row">
    <div className="col-md-6 px-5 position-relative"> 
      <img className="img-fluid strategylike teamone" src="/images/afterteam.png" alt="First Image"/>
    
      <img className='teamtwo position-absolute' src='/images/afterteamtwo.png' />
    </div>
    <div className="col-md-6 px-5 mt-5">
      {/* <p className="statusaboutagain"><span className="lines responsive-heading"></span>P R O F E S S I O N A L   <span className="skills">S K I L L S</span>  </p> */}
      <h2 className="mt-5 text-capitalize">We Make Your Business Goals Our Priority</h2>
      <p className='py-4'>Elevate your choice. We stand out with innovation, dedication, and a commitment to your success because exceptional service is our promise, and your journey deserves nothing less.</p>
    <div className='d-flex'>
        <img className='personpng' src='/images/person.png'/>
        <div className='px-3'>
        <h1>50+</h1>
        <p className='global'>Happy Global Customers Who Trusted Us</p>
        </div>
        
    </div>
    <hr></hr>
    <div className='d-flex mt-5'>
        <img className='personpng' src='/images/paperfold.png'/>
        <div className='px-3'>
        <h1>80+</h1>
        <p className='global'>Projects Completed In The Last 10 Years</p>
        </div>
   </div>
    </div>
 </div>
 





 <div id="testimonialCarousel" class="carousel slide" data-ride="carousel">
 
 <ul class="carousel-indicators">
   <li data-target="#testimonialCarousel" data-slide-to="0" class="active"></li>
   <li data-target="#testimonialCarousel" data-slide-to="1"></li>
   <li data-target="#testimonialCarousel" data-slide-to="2"></li>
 </ul>
 
 
 <div class="carousel-inner">
   <div class="carousel-item active">
     <div class="carousel-caption">
       <h1>TESTIMONIALS</h1>
       <h3>What Our Clients Are Saying ?</h3>
       <p>"I can't thank the tax experts at KMD Middle East enough for their invaluable support and assistance..."</p>
     </div>
   </div>
   <div class="carousel-item ">
       <div class="carousel-caption">
         <h3>Celina Trading LLS, Dubai,UAE</h3>
         <p>Director</p>
         <p>"I can't thank the tax experts at KMD Middle East enough for their invaluable support and assistance..."</p>
       </div>
     </div>
     <div class="carousel-item ">
       <div class="carousel-caption">
         <h3>Celina Trading LLS, Abu Dhabi,UAE</h3>
         <p>Director</p>
         <p>"I can't thank the tax experts at KMD Middle East enough for their invaluable support and assistance..."</p>
       </div>
     </div>

   <div class="carousel-item">
   
   </div>
   <div class="carousel-item">
     
   </div>
   
 </div>
 

 <a class="carousel-control-prev" href="#testimonialCarousel" role="button" data-slide="prev">
   <span class="carousel-control-prev-icon" aria-hidden="true"></span>
   <span class="sr-only"></span>
 </a>
 <a class="carousel-control-next" href="#testimonialCarousel" role="button" data-slide="next">
   <span class="carousel-control-next-icon" aria-hidden="true"></span>
   <span class="sr-only"></span>
 </a>
</div>






{/* nextone */}
<div className='container-fluid helloworld'>
    <div className='row'>
        <div className='col-md-4 mt-5  firstcontainer'>
             <div className='px-5'>
             <div className='aeroplane'>
             <img src='/images/iconsforlogo.png'/>
             </div>
             <div className='text-center'>

             <h3>Standard</h3>
                <p>Perfect For Small Business</p>
                

                <div className='pt-5 status_cont'>
                <ul className='stylenone'>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Free Consultations</span></li>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Corporate Tax Registrations</span></li>

                <li><img  className='checkmark px-1' src='/images/cross.png'/><span className='lead'>Monthly Visits to Client</span></li>
                <li><img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Virtual Assistance</span></li>
                <li><img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Monthly Accounting</span></li>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Quarterly Financial Reporting​</span></li>
                <li>  <img  className='checkmark px-1' src='/images/cross.png'/><span className='lead'>Annually Financial Reporting</span></li>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>GL & Bank Reconciliation</span></li>

                <li>  <img  className='checkmark px-1' src='/images/cross.png'/><span className='lead'>VAT Registration & VAT Return Filing</span></li>
                </ul>
                </div>

                <div className='py-5'>
                <Link to="/contact"> <button className="text-capitalize quote px-5 py-2" href="/contact">get a quote</button> </Link>
                </div>
               

             </div>
                
              
             </div>
        </div>



        <div className='col-md-4 mt-5 firstcontainer'>
             <div className='px-5 '>
             <div className='aeroplane'>
             <img src='/images/iconsfor.png'/>
             </div>
             <div className='text-center'>

             <h3>Corporate</h3>
                <p>Perfect for Companies</p>
               

                <div className='pt-5 status_cont'>
                <ul className='stylenone'>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Free Consultation</span></li>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Corporate Tax Registration</span></li>
                <li><img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Monthly Visits to Client</span></li>
                <li><img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Virtual Assistance</span></li>
                <li><img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Monthly Accounting</span></li>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Quarterly Financial Reporting​</span></li>
                <li> <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>GL & Bank Reconciliation</span></li>
                <li>  <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>Annually Financial Reporting</span></li>
                <li>  <img  className='checkmark px-1' src='/images/check mark.png'/><span className='lead'>VAT Registration & VAT Return Filing</span></li>
                </ul>
                </div>

                <div className='py-5'>
                <Link to="/contact"> <button className="text-capitalize quote px-5 py-2" href="/contact">get a quote</button> </Link>
                </div>
                    </div>
         </div>
        </div>
       
        <div className='col-md-4 mt-5'>
        <p className="statusaboutagain"><span className="lines responsive-heading"></span>Corporate  <span className="skills">Options</span>  </p>
        <h1>Our Flexible Pricing Plans</h1>
        <p className='lead'>Tailored Affordability: Embrace success without compromise through our flexible pricing plans designed to adapt to your unique needs and aspirations.</p>
<img className='img-fluid middlestatus' src='/images/afterteamtwo.png'/>
        </div>
    </div>
</div>




</div>







 </>
)


}

export default Afterteam;